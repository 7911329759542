<script setup lang="ts">
const props = defineProps<{
  controller: ReturnType<typeof useModal>
}>()

const isOpen = computed(() => props.controller.isOpen.value)
</script>

<template>
  <Teleport to="body">
    <div v-if="isOpen" ref="target" class="modal">
      <div class="background" @click="controller.close" />

      <button class="close" type="button" @click="controller.close">
        <Icon name="large-close" />
      </button>

      <div class="content">
        <slot />
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 95%;
  max-width: 1100px;
  margin: 0 auto;

  overflow-y: scroll;
  z-index: 103;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;

  @include breakpoint(ts) {
    top: 40px;
    right: 40px;
  }

  svg {
    width: 25px;
    height: 25px;

    @include breakpoint(ts) {
      width: 30px;
      height: 30px;
    }
  }
}

.content {
  padding-block: 40px;
  @include breakpoint(ts) {
    padding-block: 120px;
  }

  position: relative;
  background-color: var(--c-white);

  box-shadow: var(--shadow);
}
</style>
